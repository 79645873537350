#header_facelift{
  border-bottom: solid 1px $medium_gray;

    .ico.ico-ham {
        display: inline-block;
        padding-left: 26px;
        background: url(icons/ico-hambac.svg) no-repeat left center;
    }

    .header{

      height: 60px;
        @media(width > $mobil){
          height: 78px;
        }

        display: flex;
        align-items: center;
    }
    .logo{
      padding-right: 18px;
      position: relative;

      .partner-logo{
          display: none;
          @media(width > 740px){
            display: block;
          }
            position: absolute;
            left: 230px;
            top: 0;
            img{
                width: 150px;
            }
      }

      a{
        display: block;
        img{
          max-width: 100%;
          width: 200px;
        }
      }
      max-height: 37px;
    }
    .show-menu{
        margin-left: auto;
        height: 60px;
        line-height: 60px;
        padding-left: 20px;
        border-left: solid 1px $light_gray;
        @media(width > $mobil){
          display: none;
        }
    }
    .nav{
      display: none;
      @media(width > $mobil){
        display: block;
      }
      margin-left: auto;
      @media(width > $tablet){
        margin-top: -27px;
      }
      ul{
        li{
          margin-left: 40px;
          display: inline-block;
          font-size: 14px;
        }
        .icoLock{
          padding-left: 23px;


          &:before{
            position: absolute;
            background-image: url('icons/Lock.svg');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            width: 12px;
            height: 16px;
            content:"";
            left: 0;
            top: -1px;
          }
        }
      }
    }
}

.icoBefore{
  position: relative;
}

.container{
    lost-utility: clearfix;
}

.header-download a.btn{
  padding-left: 38px;
  padding-right: 38px;
}

#main-navigation_facelift{
  display: none;
  @media(width > $mobil){
    display: block;
  }
    .container{
        background-color: $light_gray;
        lost-utility: clearfix;
        @media(width > $tablet){
          border-top-radius: 20px;
        }

    }
    .navigation_facelift{
        display: flex;

        @media(width > $tablet){
          width: calc(99.9% * 8/12 - (20px - 20px * 8/12) - 20px);
        }

        li{
            @media(width > $tablet){
              flex-grow: 1;
            }

            border-right: 1px solid $medium_gray;
            position: relative;
            &:before{
                content: "";
                width: 1px;
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                background-color: #fff;
            };
            a{
              @media(width < $tablet){
                padding-left: 20px;
                padding-right: 20px;
              }
            }
            &:hover{
                &:before{
                    width: 0px;
                }
                a{
                    background: $dark_gray;
                    color: white;
                    position: relative;
                    &:after{
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 1px;
                        background: $dark_gray;
                        left: 0;
                        right: 0;
                        bottom: -1px;
                    }
                }
            };
            &.active{
                &:before{
                    width: 0px;

                }
                a{
                    background: $dark_gray;
                    color: white;
                    position: relative;
                    &:after{
                      content: "";
                      position: absolute;
                      width: 100%;
                      height: 1px;
                      background: $dark_gray;
                      left: 0;
                      right: 0;
                      bottom: -1px;
                    }
                }
                &:after{
                    top: 100%;
                    left: 50%;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                    border-color: rgba(153, 153, 153, 0);
                    border-top-color: #999999;
                    border-width: 10px;
                    margin-left: -10px;
                    z-index: 5;
                }
            }
            &.nav-1{
                a{
                  @media(width > $tablet){
                    border-top-left-radius: 20px;
                    span{
                        padding-left: 24px;
                    }
                  }

                }

            }
        }
        a{
            color: $superdark_grey;
            text-decoration: none;
            display: flex;
            align-items: center;
            height: 53px;
            justify-content: center;

        }
    }
}

.header-download a.buy-premium{
  font-size: 14px;
  line-height: normal;
  margin-top: 8px;
  color: $dark_orange;
  text-decoration: none;
  &:hover{
    text-decoration: underline;
  }
}

@lost gutter 20px;

$tablet: 1060px;
$mobil: 630px;


.container.with-padding{
  padding-left: 20px;
  padding-right: 20px;
    @media(width > $tablet){
      padding-left: 60px;
      padding-right: 60px;
    }
    
}
.row{
  lost-utility: clearfix;
}

.col-2{
  lost-column: 2/12;
}

.col-3{
  lost-column: 3/12;
}
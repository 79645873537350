$fixWidth : 619.36px;


.blue_gradient_line{
  position: relative;
  background-color: #0d3a7d;
  z-index: 2;
  &:after{
    content: "";
    width: 100%;
    height: 100%;
    background: radial-gradient(#22bdff, #0d3a7d);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  };
}

.blue_gradient_line .headLine{
  text-align: center;
  padding-top: 60px;
  color: white;
  font-size: 36px;

  @media(width > $mobil){
    padding-top: 66px;
    line-height: 1em;
    font-size: 48px;
  }
}

.for_platforms_cover{
  margin-left: -20px;
  margin-right: -20px;
  overflow: hidden;

  .for_platforms{
      padding-right: 20px;
      padding-left: 20px;
      padding-bottom: 20px;
  }
  .navs{
      position: relative;
      width: 100%;
      @media(width > 600px){
          display: none;
      }
      .nav{
          position: absolute;
          width: 73px;
          height: 73px;
          border-radius: 100%;
          border: solid 1px $light_gray;
          background: white;
          bottom: 2px;
          &:before{
                content: "";
                background-position: center center;
                width: 100%;
                height: 100%;
                width: 15px;
                height: 15px;
                background-size: 8px;
                background-repeat: no-repeat;
                position: absolute;
                top: 28px;
          }
          &.preview{
              left: -44px;
              &:before{
                  right: 6px;
                  background-image: url(icons/BackBlue.svg);
              }
          }
          &.next{
              right: -44px;
              &:before{
                  left: 6px;
                  background-image: url(icons/ForwardBlue.svg);
              }
          }
      }
  }
}

.for_platforms{
    padding-top: 30px;
    lost-utility: clearfix;
    @media(width < $tablet){
      width: 960px;
    }
    li{
      float: left;
      border: solid 1px $light_gray;
      margin-right: 10px;
      a{
        display: flex;
        height: 36px;
        font-size: 14px;
        align-items: center;
        padding-left: 14px;
        padding-right: 14px;
        &:before{

          background-repeat: no-repeat;
          background-size: 100% 100%;
          width: 20px;
          height: 20px;
          content:"";
          margin-right: 10px;
        }
      }
       &:hover{
           @media(width > 700px){
               background-color: $medium_blue;
               a{
                 color: white;
               }
           }
       }
      &.active{
        background-color: $medium_blue;
        a{
          color: white;
        }
      }
      &.all_platforms{
        a{
          &:before{
            background-image: url('icons/FullMoon.svg');
          }
        }
        &.active, &:hover{
          a{
            &:before{
              background-image: url('icons/FullMoonBlue.svg');
            }
          }
        }
      }
      &.win_platform{
        a{
          &:before{
            width: 19px;
            height: 19px;
            background-image: url('icons/Windows8.svg');
          }
        }
        &.active, &:hover{
          a{
            &:before{
              background-image: url('icons/Windows8BlueWhite.svg');
            }
          }
        }
      }
      &.android_platform{
        a{
          &:before{
            width: 16px;
            height: 20px;
            background-image: url('icons/AndroidOS.svg');
          }
        }
        &.active, &:hover{
          a{
            &:before{
              background-image: url('icons/AndroidOSBlue.svg');
            }
          }
        }
      }
      &.mac_platform{
        a{
          &:before{
            background-image: url('icons/MacOS.svg');
          }
        }
        &.active, &:hover{
          a{
            &:before{
              background-image: url('icons/MacOSBlue.svg');
            }
          }
        }
      }
      &.ios_platform{
        a{
          &:before{
            width: 24px;
            height: 13px;
            background-image: url('icons/iOS-7-Logo-5.svg');
          }
        }
        &.active, &:hover{
          a{
            &:before{
              background-image: url('icons/iOS-7-Logo-5Blue.svg');
            }
          }
        }
      }
    }
}

.topics_background{
  @media(width < $tablet){
    background-color: $medium_blue;
  }
}

.topics_container{
  position: relative;
  overflow: hidden;
  margin-left: -20px;
  margin-right: -20px;
  .navs{

    @media(width > 922px){
      display: none;
    }


    .nav{
      position: absolute;
      width: 30px;
      height: 100%;
      top: 0;
      z-index: 6;
      outline: none;

      &:before{
        position: absolute;
        content: "";
        background-repeat: no-repeat;
        width: 20px;
        height: 100%;
        background-size: 10px;

      }
      &:after{
          position: absolute;
          content: "";
          height: 100%;
          top: 0;
          width: 25px;
      }
      &.preview{
        left: 0px;

    //    background: linear-gradient(to right, rgba(8,149,208,1) 22%,rgba(8,149,208,0.55) 57%,rgba(112,176,224,0) 100%);
        &:before{
          background-color: $medium_blue;
          background-image: url('icons/BackWhite.svg');
          background-position: right center;
          left: 0;
        }
        &:after{
            right: -15px;
            background: linear-gradient(to right, rgba(8,149,208,1) 22%,rgba(8,149,208,0.55) 57%,rgba(112,176,224,0) 100%);
        }


      }
      &.next{
        right: 0px;
    //    background: linear-gradient(to left, rgba(8,149,208,1) 22%,rgba(8,149,208,0.55) 57%,rgba(112,176,224,0) 100%);
        &:before{
            background-color: $medium_blue;
            background-image: url('icons/ForwardWhite.svg');
            background-position: left center;
            right: 0;
        }
        &:after{
            left: -15px;
            background: linear-gradient(to left, rgba(8,149,208,1) 22%,rgba(8,149,208,0.55) 57%,rgba(112,176,224,0) 100%);
        }

      }
    }
  }
}




#topics{
  padding-top: 12px;
  @media(width > $tablet){
    padding-top: 42px;
  }

  width: 940px;
  overflow: hidden;




  ul{
    lost-utility: clearfix;
    li{
       lost-column: 1/9 9 0px;
      a{
        display: block;
        height: 102px;
        padding-top: 54px;
        color: $superlight_blue;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        z-index: 3;
        position: relative;
      }
      position: relative;
      &:before{
        position: absolute;

        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 32px;
        height: 32px;
        content:"";
        left: 50%;
        margin-left: -16px;
        top: 10%;
        margin-top: 0px;
      }
      &.active{
        background-color: #fff;
        a{
          color: $medium_blue;
        }
      }

      &:hover{
        @media(width > $tablet){
          background-color: #fff;
          a{
            color: $medium_blue;
          }
        }
      }

      &.all_topics{
        &:before{
          top: 10px;
          width: 28px;
          height: 28px;
          margin-left: -14px;
          background-image: url('icons/FullMoonWhite.svg');
        }
        &.active{
          &:before{
            background-image: url('icons/FullMoon.svg');
          }
        }
        &:hover{
          &:before{
            @media(width > $tablet){
              background-image: url('icons/FullMoon.svg');
            }
          }
        }
      }

      &.general{
        &:before{
          background-image: url('icons/InfoWhite.svg');
        }
        &.active{
          &:before{
            background-image: url('icons/Info.svg');
          }
        }
        &:hover{
          &:before{
            @media(width > $tablet){
              background-image: url('icons/Info.svg');
            }
          }
        }
      }

      &.instalation{
        &:before{
          width: 27px;
          height: 27px;
          margin-left: -13px;
          background-image: url('icons/SoftwareInstallerWhite.svg');
        }
        &.active{
          &:before{
            background-image: url('icons/SoftwareInstaller.svg');
          }
        }
        &:hover{
          &:before{
            @media(width > $tablet){
              background-image: url('icons/SoftwareInstaller.svg');
            }
          }
        }
      }

      &.activation{
        &:before{
          width: 34px;
          height: 29px;
          margin-left: -17px;
          background-image: url('icons/PaidWhite.svg');
        }
        &.active{
          &:before{
            background-image: url('icons/Paid.svg');
          }
        }
        &:hover{
          &:before{
            @media(width > $tablet){
              background-image: url('icons/Paid.svg');
            }
          }
        }
      }

      &.stared{
        &:before{
          width: 20px;
          height: 26px;
          margin-left: -10px;
          background-image: url('icons/PlayWhite.svg');
        }
        &.active{
          &:before{
            background-image: url('icons/Play.svg');
          }
        }
        &:hover{
          &:before{
            @media(width > $tablet){
              background-image: url('icons/Play.svg');
            }
          }
        }
      }

      &.autofil{
        &:before{
          width: 36px;
          height: 28px;
          margin-left: -18px;
          background-image: url('icons/GoodPincodeWhite.svg');
        }
        &.active{
          &:before{
            background-image: url('icons/GoodPincode.svg');
          }
        }
        &:hover{
          &:before{
            @media(width > $tablet){
              background-image: url('icons/GoodPincode.svg');
            }
          }
        }
      }

      &.mobile{
        &:before{
          width: 20px;
          height: 28px;
          margin-left: -10px;
          background-image: url('icons/AndroidWhite.svg');
        }
        &.active{
          &:before{
            background-image: url('icons/Android.svg');
          }
        }
        &:hover{
          &:before{
            @media(width > $tablet){
              background-image: url('icons/Android.svg');
            }
          }
        }
      }

      &.sync{
        &:before{
          width: 24px;
          height: 34px;
          margin-left: -12px;
          background-image: url('icons/SynchronizeWhite.svg');
        }
        &.active{
          &:before{
            background-image: url('icons/Synchronize.svg');
          }
        }
        &:hover{
          &:before{
            @media(width > $tablet){
             background-image: url('icons/Synchronize.svg');
            }
          }
        }
      }

      &.tips{
        &:before{
          width: 34px;
          height: 34px;
          margin-left: -17px;
          background-image: url('icons/IdeaWhite.svg');
        }
        &.active{
          &:before{
            background-image: url('icons/Idea.svg');
          }
        }
        &:hover{
          &:before{
            @media(width > $tablet){
              background-image: url('icons/Idea.svg');
            }
          }
        }
      }
    }
  }


  .bx-controls{
    display: none;
  }

}

.section_header{
  lost-utility: clearfix;
  .section_header_inner{
    @mixin padding bottom, 5;



    @media(width > 650px){
        width: $fixWidth;
        float: left;
    }

    @media(width > $tablet){
      @mixin padding bottom, 7;
    }

  }
  @media(width > $tablet){
    float: left;
    width: 100%;
  }

}

.searchLine{
  position: relative;
  input[type=text]{
      width: 100%;
      position: relative;
      z-index: 10;
      padding-right: 46px;
      outline: 0;
  }
  button{
    width: 50px;
    height: 50px;
    position: absolute;
    right: 1px;
    top: 1px;
    z-index: 11;
    &:before{
      position: absolute;
      background-image: url('icons/Search.svg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 26px;
      height: 26px;
      content:"";
      left: 50%;
      margin-left: -13px;
      top: 50%;
      margin-top: -13px;
    }
  }
}

.side_bar{
  @media(width > $tablet){
    lost-column: 3/12 3;
    padding-top: 0px;
  }
  input[type=text]::placeholder{
    font-size: 16px;
  }

  .help_box{
    position: relative;
    padding-bottom: 48px;
    @media(width > $tablet){
      padding-top: 0px;
      padding-bottom: 0px;
    }

    ul{
      display: none;
      @media(width > $tablet){
        display: block;
      }
      padding-top: 36px;
    }
    li{
      font-size: 16px;
      line-height: 1em;
      padding-bottom: 12px;
      &:last-child{
        padding-bottom: 0px;
      }
    }
  }
}

.sections{
    margin-right: 20px;
    @media(width > 650px){
        width: $fixWidth;

    }
    @media(width > 1060px){
        float: left;
    }
}


#autocomplete{
  position: absolute;
  background-color: white;
  border: solid 1px $medium_gray;





  .search_faqs{
    display: block;
  }

  .search_categories{
      margin-left: 9px;
      margin-right: 9px;
    @media(width > 971px){
      margin-left: 0;
      margin-right: 0;
    }
    a{
      display: flex;
      align-items: center;
      @mixin padding bottom, 2;
      @mixin padding top, 2;
      @media(width > 971px){
        @mixin padding bottom, 4;
        @mixin padding top, 4;
      }
      padding-left: 5px;
      padding-right: 5px;
      @media(width > 971px){
        padding-left: 18px;
        padding-right: 18px;
      }

      &:hover{
        background-color: $superlight_yellow;
        text-decoration: none;

      }

      img{

        width: 18px;
        margin-right: 12px;
        @media(width > 971px){
          width: 27px;
          margin-right: 20px;
        }
      }
      font-size: 16px;
      @media(width > 971px){
        font-size: 21px;
      }

      span{
        font-size: 14px;
        @media(width > 971px){
          font-size: 16px;
        }
        text-decoration: none;
        color: $dark_gray;
        padding-left: 8px;
      }

    }
    border-bottom: solid 1px $light_gray;
  }
  .search_faqs{
    margin-left: 9px;
    margin-right: 9px;
    @media(width > 971px){
      margin-left: 0px;
      margin-right: 0px;
      border-bottom: solid 1px $light_gray;
    }


    .search_faq{

      border-bottom: solid 1px $light_gray;
      @media(width > 971px){
        border-bottom: none;
      }

      a{
        display: block;
        padding-left: 18px;
        padding-right: 18px;
        @mixin padding bottom, 2;
        @mixin padding top, 2;
        @media(width > 971px){
          @mixin padding bottom, 3;
          @mixin padding top, 3;
        }
        &:hover{
          text-decoration: none;
          background-color: $superlight_yellow;
        };
        &.active{
          text-decoration: none;
          background-color: $superlight_yellow;
        }
        h4{
          font-size: 16px;
          @media(width > 971px){
            font-size: 21px;
          }

        }
        p{
          @media(width < 971px){
            font-size: 14px;
          }
          @mixin padding top, 1;
          color: $superdark_grey;
        }
      }
    }
  }
  .see_all_results{
    text-align: center;
    @mixin padding top, 4;
    @mixin padding bottom, 4;
    a{
      position: relative;
      @media(width < 971px){
        font-size: 14px;
      }
      &:after{
        position: absolute;
        background-image: url('icons/ForwardBlue.svg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 8px;
        height: 15px;
        content:"";
        top: 2px;
        right: -13px;
        @media(width > 971px){
          top: 3px;
        }
      }
    }
  }
}

.side_bar{
  #autocomplete{
    z-index: 1;
    @media(width > 1060px){
      right: -10px;
      top: 37px;
    }
    width: 100%;
    @media(width > 1060px){
      width: 721px;
      border: solid 11px $medium_gray;
    }
    ul{
        padding-top: 0 !important;
    }
  }
}


#helpPage{
    .blue_gradient_line{
        .searchLine{
          margin: 0 auto;
          margin-top: 24px;
          margin-bottom: 48px;
          position: relative;
          @media(width > $mobil){
            margin-top: 42px;
            margin-bottom: 61px;
            width: calc(99.9% * 6/12 - (20px - 20px * 6/12));
          }
        }

        #autocomplete{
          left: 0px;
          top: 100%;
          margin-top: -1px;
          @media(width > 971px){
            border: solid 11px $medium_gray;
            left: -10px;
            top: 37px;
            width: 721px;
          }

        }
    }



}






.help_crossroad{
  padding-top: 60px;
  .sections{
    .line{
      lost-utility: clearfix;
      &.second{
        border-top: solid 1px $light_gray;
        padding-top: 40px;
      }
    }

    .sections_cover{
      height: 505px;
      overflow: hidden;
      &.show_full{
        height: auto;
      }
      @media(width > $mobil){
        height: auto;
      }
    }

    .section{

      a{
        padding-top: 68px;
        background-image: url("../icons/info.png");
        background-repeat: no-repeat;
        background-position: top center;
        padding-bottom: 40px;
        display: block;

      }
      lost-column: 1/2;
      @media(width > $mobil){
        lost-column: 1/4;
      }
      text-align: center;
      font-size: 21px;
      line-height: 24px;

      a{
        background-size: auto 46px;
      }

      &.section-general{
        a{
          background-image: url("icons/Info.svg");
          background-size: 50px auto;
        }
      }
      &.section-install{
        a{
          background-image: url("icons/SoftwareInstaller.svg");
          background-size: 44px auto;
        }
      }
      &.section-activate{
        a{
          background-image: url("icons/Paid.svg");
          background-size: 56px auto;
        }
      }
      &.section-started{
        a{
          background-image: url("icons/Play.svg");
          background-size: 34px auto;
        }
      }
      &.section-autofill{
        a{
          background-image: url("icons/GoodPincode.svg");
          background-size: 60px auto;
          background-position: center 7px;
        }
      }
      &.section-mobile{
        a{
          background-image: url("icons/Android.svg");
          background-size: 32px auto;
        }
      }
      &.section-sync{
        a{
          background-image: url("icons/Synchronize.svg");
          background-size: 40px auto;
        }
      }
      &.section-tip{
        a{
          background-image: url("icons/Idea.svg");
          background-size: 57px auto;
        }
      }
      &.section-win{
        a{
          background-image: url("icons/Windows8.svg");
          background-size: 44px auto;
        }
      }
      &.section-android{
        a{
          background-image: url("icons/AndroidOS.svg");
          background-size: 41px auto;
        }
      }
      &.section-mac{
        a{
          background-image: url("icons/MacOS.svg");
          background-size: 57px auto;
        }
      }
      &.section-ios{
        a{
          background-image: url("icons/iOS-7-Logo-5.svg");
          background-position-y: 16px;
          background-size: 53px auto;
        }
      }
    }
  }

  .show_all_categories{
    @media(width > $mobil){
      display: none;
    }

    margin-top: -60px;
    margin-left: -20px;
    margin-right: -20px;
    text-align: center;
    position: relative;
    .shadow{
      height: 60px;
      background: linear-gradient(rgba($light_gray, 0), rgba($light_gray ,.6) 60%);
    }
    a{
      border-top: solid 1px $light_gray;
      padding-top: 24px;
      padding-bottom: 24px;
      display: block;
    }
  }

  .side_bar{
    font-size: 16px;
    padding-top: 42px;
    @mixin padding bottom, 7;
    @media(width > $tablet){
      padding-top: 0px;
      padding-bottom: 0;
    }

    h4{
    }
    ul.questions{
      line-height: 24px;
      li{
        @mixin padding top, 5;
      }
    }
  }
}

.discusion_row{
  display: none;
  @media(width > $tablet){
    display: block;
  }
  padding-top: 48px;
  padding-bottom: 48px;
  text-align: center;
  a{

  }
}


.premium_support{
  @mixin padding top, 8;
  @mixin padding bottom, 8;
  @media(width > $mobil){
    padding-top: 60px;
    padding-bottom: 72px;
  }
  text-align: center;
  background: $superlight_gray;
  .text{
    padding-top: 30px;
    padding-bottom: 18px;
    @media(width > $tablet){
      display: none;
    }
  }
}

.premium_support_form{
  @mixin padding top, 8;
  @mixin padding bottom, 8;
  @media(width > $mobil){
    padding-top: 60px;
    padding-bottom: 72px;
  }
  text-align: center;
  background: $superlight_gray;
  h4{
    font-size: 21px;
    @mixin padding bottom, 3;
    @media(width > $tablet){
      font-size: 36px;
      line-height: 1em;
      @mixin padding bottom, 4;
    }

  }
  p{
    font-size: 14px;
    @media(width > $tablet){
      font-size: 16px;
    }
    color: $dark_gray;
    @mixin padding bottom, 5;
  }
  .spForm{
    max-width: 460px;
    margin: 0 auto;
  }

}



.help_faqs_list{



  .faq_item{
       position: relative;
    @media(width > $mobil){
      display: flex;
    }

    border-bottom: solid 1px $border;
    @mixin padding bottom, 3;
    @mixin margin bottom, 3;
    @media(width > $tablet){
      border-bottom: none;
      @mixin padding bottom, 4;
      @mixin margin bottom, 4;
    }
  }



  .faq_item{
    &:last-child{
      padding-bottom: 0px;
      margin-bottom: 0px;
      border-bottom: none;
    }
  }
  .faq_headline{
    h3{

      font-size: 16px;
      line-height: 24px;

      @media(width > $tablet){
        line-height: 1em;
        font-size: 21px;
      }
    }
    small{
        font-size: 14px;
        color: $dark_gray;
        position: absolute;
            bottom: 21px;
        right: 0;
    }
    span{
      font-size: 14px;
      color: $dark_gray;
    }
  }
  .rating{
    padding-right: 12px;
    padding-bottom: 12px;
    span{
      background-color: $superlight_yellow;
      font-size: 14px;
      display: inline-block;

      color: $dark_red;
      padding-left: 6px;
      padding-right: 6px;
      padding-bottom: 3px;
      padding-top: 3px;
    }
  }


}


.help_category{
  @mixin padding bottom, 9;
  @media(width > $mobil){
    /*@mixin padding bottom, 16;*/
  }
  .show_all_faq_items{
    display: none;
    &.active{
      display: block;
    }
    @media(width > $tablet){
      display: none !important;
    }

    padding-top: 24px;
    text-align: center;
    font-size: 14px;
  }
}


.faq_page{
  .back{
    @mixin padding top, 3;
    @mixin padding bottom, 3;
    font-size: 14px;
    @media(width > $mobil){
      font-size: 16px;
      @mixin padding top, 6;
      @mixin padding bottom, 3;
    }

  }
  .platforms{
    @mixin padding top, 4;
    color: $dark_gray;
    font-size: 14px;
  }
  .related_faq{

    @mixin padding top, 7;
    @mixin padding bottom, 6;

    @media(width > $mobil){
      @mixin padding top, 13;
      @mixin padding bottom, 16;
    }

    h4{
      @extend %bold;
      @mixin padding bottom, 4;
      @media(width > $tablet){
        @mixin padding bottom, 7;
      }

    }

  }

  .helpful_help_faqs_list{
    @media(width > $tablet){
      float: left;
      width: 100%;
    }

  }
  .yt-video-view{
    position: relative;
    padding-bottom: 56.25%;
    height: 0
  }
  iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}



.faq_page_row{
  @media(width < $tablet){
    display: flex;
    flex-direction: column;
    .sections{
      order: 0;
    }
    .helpful_help_faqs_list{
      order: 1;
    }
    .side_bar{
      order: 2;
    }
  }
}


.helpful_content{
     margin-top: 36px;
     @media(width > $mobil){
       margin-top: 60px;
     }
     margin-left: -20px;
     margin-right: -20px;
     background-color: $superlight_gray;
     padding-left: 20px;
     padding-right: 20px;
     @mixin padding top, 4;
     @mixin padding bottom, 4;
}

#helpful_no{
    display: none;
    p{
          @extend %bold;
           @mixin padding bottom, 3;
    }
    textarea{
        height: 70px;
         @mixin margin bottom, 3;
    }
    .btn{
        padding-left: 40px;
        padding-right: 40px;
    }
}

#helpful_thanks, #helpful_no_thanks{
    display: none;
    p{
        background-image: url(icons/OkGreen.svg);
        background-repeat: no-repeat;
        background-size: 22px 22px;
        background-position: left center;
        color: $dark_green;
        line-height: 25px;
        padding-left: 30px;
    }
}

#helpful_error{
    display: none;
}

.helpful{

  text-align: center;
  @media(width > $mobil){
    display: flex;
  }
  align-items: center;

  p{
    padding-top: 0;
    @mixin padding bottom, 2;
    @media(width > $mobil){
      padding-bottom: 0;
    }
    color: $dark_gray;
    padding-right: 10px;
  }
  .btn{
    min-width: 80px;
    margin-left: 10px;
  }
}


.search_result_page{
  .blue_gradient_line .headLine{
    text-align: left;
  }
  .side_bar .help_box ul{
    padding-top: 0px;
  }
  .side_bar{
    @media(width < $tablet){
      display: none;
    }
  }
}
#helpPage.search_result_page{
  .blue_gradient_line .searchLine{
    margin-left: 0;
  }
}

.pagination_list{
    li{
        &.hide{
          display: none !important;
        }
    }
    &.view_lines{
        li{
            &:nth-child(10n){
              border-bottom: solid 1px $border;
            }
        }
    }
}



.search_result{
    li{
      @mixin margin bottom, 4;
      @mixin padding bottom, 4;
      border-bottom: solid 1px $border;
      @media(width > $tablet){
        border-bottom: none;
      }



      &:last-child{
        margin-bottom: 0px;
      };
    }
  h3{
    font-size: 16px;
    line-height: 24px;
    @media(width > $tablet){
      font-size: 21px;
      line-height: 24px;
    }

    @mixin padding bottom, 2;
  }
  p{
    line-height: 24px;
  }

}

.pagination{
  @mixin padding bottom, 6;
  @media(width < $tablet){
    @mixin padding bottom, 8;
  }

  text-align: center;
  .viewing{
    color: $dark_gray;
    @media(width < $tablet){
      font-size: 14px;
    }
  }
  .show_more{
    @mixin padding top, 2;
    @mixin padding bottom, 4;
    @media(width > $tablet){
      @mixin padding top, 3;
      @mixin padding bottom, 6;
    }

  }
  .show_all{
    @media(width < $tablet){
      font-size: 14px;
    }
  }
}

.premium_support_success_send{
    display: none;
    text-align: center;
    @mixin padding bottom, 21;
    @mixin padding top, 8;
    h3{
        @mixin padding top, 13;
        color: $dark_green;
        font-size: 36px;
        @mixin padding bottom, 7;
        background-image: url(icons/OkGreen.svg);
        background-repeat: no-repeat;
        background-size: 50px 50px;
        content: "";
        background-position: center top;
    }
    background: $superlight_gray;
    .success_message{
        p{
            line-height: 24px;
            &:first-child{
                @mixin padding bottom, 6;
            }
            strong{
                display: block;
            }
        }
    }
}

.faq-make-red{
    color: $dark_red !important;
}


.faq_detail{

    a.faq-lightbox, a.lightbox{
        outline: none;
    }

    h2, h3, h4, p, ul, ol, div{
        &:first-child{
            padding-top: 0px !important;
        }
    }

    h2{
        padding-top: 50px;
        font-size: 36px;
    }
    ul{
        padding-top: 30px;
        & > li{
            font-size: 16px;
            line-height: 24px;
            position: relative;
            padding-left: 30px;
            padding-bottom: 6px;
            &:last-child{
                padding-bottom: 0px;
            }
            &:before{
                content: "•";
                position: absolute;
                left: 0;
            }
            img{
                padding-top: 0px !important;
                padding-bottom: 0px  !important;
                margin-top: 6px;
                margin-bottom: 12px;
                max-height: 340px;
            }
        }
        &.check-list{
            & > li{
                position: relative;
                &:before{
                    position: absolute;
                    background-image: url('icons/CheckListGreen.svg');
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    width: 17px;
                    height: 17px;
                    content:"";
                    left: 0;
                    top: 4px;
                }
            }
        }
    }

    ol{
        counter-reset: upgrade-counter;

        & > li{
            margin-top: 42px;
            position: relative;
            padding-left: 25px;
            &:before{
                content: counter(upgrade-counter) ".";
                counter-increment: upgrade-counter;
                position: absolute;
                left: 0px;
                top: 4px;
                font-size: 16px;
            }
        }
    }

    ol.faq-numbered-h2{
        & > li{
            position: relative;
            padding-left: 0px;
            &:before{
                content: counter(upgrade-counter) ".";
                counter-increment: upgrade-counter;
                position: absolute;
                left: 0px;
                top: 14px;
                font-size: 36px;
            }
            h2{
                padding-left: 32px;
            }
        }
    }
    ol.faq-numbered-h3{

        & > li{
            padding-left: 30px;
            &:before{
                content: counter(upgrade-counter) ".";
                counter-increment: upgrade-counter;
                position: absolute;
                left: 0px;
                top: 10px;
                font-size: 24px;
                @extend %bold;
            }
            h3{
                padding-top: 0px;
            }
            img{
                padding-top: 0px !important;
                padding-bottom: 0px  !important;
                margin-top: 6px;
                margin-bottom: 12px;
            }
        }
    }
}

input[type=text], select.form-control{

    height: 46px;
    @media(width > 630px){
    line-height: 48px;
      height: 48px;
    }
    width: 100%;
    padding-left: 14px;
    padding-right: 14px;
    border: solid 1px $medium_gray;
    font-size: 16px;
    background-color: white;
    @media(width > $mobil){
      font-size: 21px;
    }
}
select{
    width: 100%;
}
.select_cover{
    border: solid 1px $medium_gray;
    padding-left: 14px;
    padding-right: 14px;
    background: white;
    select{
        border: none;
        outline: none;
    }
}

textarea{
  padding: 14px;
  height: 240px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.0);
  border: solid 1px $medium_gray;
  font-size: 16px;
  line-height: 1em;
  color: $superdark_grey;
  @media(width > $mobil){
    font-size: 21px;
  }
}
input[type=text], textarea{
  font-weight: normal;

}
input[type=text]:focus, textarea:focus{
  outline-color: $superlight_yellow;
}




::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $placeholder;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $placeholder;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $placeholder;
}
:-moz-placeholder { /* Firefox 18- */
  color: $placeholder;
}

.spForm{
  .form-group{
    position: relative;
  }
  label{
    padding-left: 14px;
    position: relative;
    display: block;
    font-size: 16px;
    @mixin margin top, 4;
    @mixin padding bottom, 2;
    @media(width > $tablet){
      font-size: 21px;
      @mixin margin top, 6;
      @mixin padding bottom, 3;
    }

    text-align: left;
  }
  .message{
    display: none;
    text-align: left;
    padding-left: 14px;
    padding-top: 8px;
    @media(width > $tablet){
      padding-top: 0;
      position: absolute;
      width: 215px;
      right: -215px;
      top: 32px;
    }

  }
  input[type=text]{
    width: 100%;
  }
  .drop_zone{
    text-align: left;
    color: $dark_gray;
    border: dashed 3px $medium_gray;
    min-height: 90px;
    padding: 14px;
    p{
      @mixin padding bottom, 8;
    }
    .files{
      color: $superdark_grey;
      li{
        display: flex;
        align-items: center;
        .actions{
          margin-left: auto;
        }
        @mixin padding bottom, 3;

      }
    }
    .actions{
      display: flex;
      align-items: center;
      color: $dark_gray;
    }
    .remove{
      display: block;
      position: relative;
      background-image: url('icons/TrashBlue.svg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      content:"";
      width: 16px;
      height: 20px;
      @media(width > $tablet){

      }
    }
    .cancel{
      margin-left: 20px;
      display: inline-block;
      position: relative;
      background-image: url('icons/Multiplyblue.svg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      content:"";
      width: 15px;
      height: 15px;
      @media(width > $tablet){

      }
    }
  }
  .button{

    @mixin padding top, 7;
    .btn{
        position: relative;
        padding-left: 70px;
        padding-right: 70px;
        .spin{
            display: none;
            position: absolute;
            top: 22px;
            left: 30px;
            animation: spin 2s linear infinite;
        }
    }
  }

  .valid{
    color: $dark_green;
    span{
      position: relative;
      &:before{
        position: absolute;
        background-image: url('icons/CheckGreen.svg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        content:"";
        width: 17px;
        height: 11px;
        right: -25px;
        top: 6px;
        @media(width > $tablet){
          width: 23px;
          height: 16px;
          right: -35px;
        }
      }
    }
    input[type=text], textarea{
      border-color: $dark_green;
    }
  }
  .in-valid{
    color: $medium_red;
    span{
      position: relative;
      &:before{
        position: absolute;
        background-image: url('icons/MultiplyRed.svg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        content:"";
        width: 17px;
        height: 11px;
        right: -25px;
        top: 6px;
        @media(width > $tablet){
          width: 23px;
          height: 16px;
          right: -35px;
        }

      }
    }
    input[type=text], textarea{
      border-color: $medium_red;
    }
  }

}

.fltooltip{
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  background-color: $medium_gray;
  border-radius: 100%;
  color: white;
  font-size: 18px;
  position: absolute;
  right: 14px;
  top: -4px;
}

.tooltip{
  &.in{
    filter: alpha(opacity = 100);opacity: 1
  }
  .tooltip-inner{
    background-color: $superdark_grey;
    border-radius: 0;
    padding: 18px !important;
    font-size: 16px;
    line-height: 24px;
    width: 380px !important;
  }
}
.tooltip.top .tooltip-arrow {
  border-top-color: $superdark_grey;
}

.report-url-form{
    .form_place{
        margin-top: 30px;
    }
    p{
        padding-top: 30px;
        font-size: 16px !important;
        line-height: 24px !important;
    }
    .dot{
        display: none;
    }
}

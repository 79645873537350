#footer{
    font-size: 14px;
    background-color: $light_gray;

    .container.with-padding{
      @media(width < 630px){
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    
    .desktop-footer{
        display: none;
        @media(width > 1060px){
          display: block;
        }
    }
    

    .headline{
        padding-top: 60px;
       padding-bottom: 35px !important;
        text-transform: uppercase;
    }
    .links{
       li{
           padding-bottom: 8px;
          line-height: 16px;
       } 
       .external_link{
        &:before{
          right: -9px;
        }
       }
    }
    
    .save-manatee{
        position: relative;
        padding-top: 15px;
        .why{
            position: absolute;
            right: 10px;
            bottom: 18px;
        }
    }

    .buttons{
        padding-bottom: 66px;
        li{
            display: flex;
            &:first-child{
                padding-bottom: 12px;
            }
        }
        .btn{
            width: 220px;
        }
        .social_media{
            margin-left: auto;
            lost-utility: clearfix;
            a{
                display: block;
                float: left;
                margin-left: 14px;
            }
        }
    }

    .very_bottom{
        padding-top: 40px;
        padding-bottom: 30px;
        @media(width > 1060px){
            background: $medium_gray;
            padding-top: 34px;
            padding-bottom: 34px;
        }
        
        
        
        .container{
            @media(width > 1060px){
                display: flex;
                align-items: center;
            }
            
        }
        .app-store{
            height: 50px;
            ul{
                display: flex;
                @media(width < 1060px){
                    justify-content: center;
                }

                li{
                    &:first-child{
                        @media(width > 1060px){
                            padding-right: 18px;
                        }
                    }
                }
            }
        }
        .buy_premium{
            padding-top: 24px;
            padding-bottom: 60px;
            text-align: center;
            a{
                color: $dark_orange;
            }
            @media(width > 1060px){
                display: none;
            }
        }
        .copy_right{
            margin-left: auto;
            @media(width < 1060px){
                text-align: center;
                color: $dark_gray;
            }
        }
    }

    .mobile_languages{
        border-top: solid 1px $medium_gray;
        padding-top: 24px;
        padding-bottom: 12px;
        @media(width > 1060px){
            display: none;
        }
        ul{
            text-align: center;
        }
        li{
            display: inline-block;
            padding-left: 14px;
            padding-right: 14px;
            padding-bottom: 18px;
        }
    }
}



.external_link{
    position: relative;
    &:before{
      position: absolute;
      background-image: url('icons/UpRight.svg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 8px;
      height: 8px;
      content:"";
      right: -13px;
      top: 3px;
    }
}

.back_link{
  position: relative;
  padding-left: 14px;
  &:hover{
      cursor: pointer;
  }
  &:before{
    position: absolute;
    background-image: url('icons/Back.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 8px;
    height: 14px;
    content:"";
    left: 0;
    top: 3px;
  }
}

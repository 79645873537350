    @font-face{
        font-family:"Helvetica Neue LT W01_55 Roman ";
        src:url("Fonts/bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.eot?#iefix");
        src:url("Fonts/bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.eot?#iefix") format("eot"),url("Fonts/3dac71eb-afa7-4c80-97f0-599202772905.woff2") format("woff2"),url("Fonts/34e0e4c0-c294-49bb-9a8e-1b2cafd54e32.woff") format("woff"),url("Fonts/8a8bfee3-197c-4942-9b11-71508cc9f406.ttf") format("truetype"),url("Fonts/5101995a-e73b-4cf9-84e8-f99eb43277b1.svg#5101995a-e73b-4cf9-84e8-f99eb43277b1") format("svg");
    }
    @font-face{
        font-family:"Helvetica Neue LT W01_56 It";
        src:url("Fonts/5d0e5dca-869d-4e14-ac6b-45fbab3c4047.eot?#iefix");
        src:url("Fonts/5d0e5dca-869d-4e14-ac6b-45fbab3c4047.eot?#iefix") format("eot"),url("Fonts/21c44514-f4d6-4cff-a5de-e4cac5e61aff.woff2") format("woff2"),url("Fonts/e7c4b231-76ad-47c7-a54b-5d84dcd78d0f.woff") format("woff"),url("Fonts/b6db417f-e795-426a-a2af-adc37a3419a6.ttf") format("truetype"),url("Fonts/0c6d116c-89d1-402b-b9f3-2d9890d7a4ee.svg#0c6d116c-89d1-402b-b9f3-2d9890d7a4ee") format("svg");
    }
    @font-face{
        font-family:"Helvetica Neue LT W01_71488914";
        src:url("Fonts/8a6c3e5a-88a5-430c-91d8-7767ce971d12.eot?#iefix");
        src:url("Fonts/8a6c3e5a-88a5-430c-91d8-7767ce971d12.eot?#iefix") format("eot"),url("Fonts/531c5a28-5575-4f58-96d4-a80f7b702d7b.woff2") format("woff2"),url("Fonts/439c5962-f9fe-4eaf-a1f6-f41d42edba75.woff") format("woff"),url("Fonts/419a308d-b777-4f84-9235-2caf4a98ec23.ttf") format("truetype"),url("Fonts/e4d5b881-6835-45b7-8766-3e5b9e7cab8b.svg#e4d5b881-6835-45b7-8766-3e5b9e7cab8b") format("svg");
    }

    @font-face {
        font-family: 'Helvetica Neue LT W01_71488914 cyr';
        src: url('Rfont/HelveticaNeueCyr-Bold.eot');
        src: url('Rfont/HelveticaNeueCyr-Bold.eot?#iefix') format('embedded-opentype'),
        url('Rfont/HelveticaNeueCyr-Bold.woff2') format('woff2'),
        url('Rfont/HelveticaNeueCyr-Bold.woff') format('woff'),
        url('Rfont/HelveticaNeueCyr-Bold.ttf') format('truetype'),
        url('Rfont/HelveticaNeueCyr-Bold.svg#HelveticaNeueCyr-Bold') format('svg');
    }

    @font-face {
        font-family: 'Helvetica Neue LT W01_56 It cyr';
        src: url('Rfont/HelveticaNeueCyr-Italic.eot');
	    src: url('Rfont/HelveticaNeueCyr-Italic.eot?#iefix') format('embedded-opentype'),
		url('Rfont/HelveticaNeueCyr-Italic.woff2') format('woff2'),
		url('Rfont/HelveticaNeueCyr-Italic.woff') format('woff'),
		url('Rfont/HelveticaNeueCyr-Italic.ttf') format('truetype'),
		url('Rfont/HelveticaNeueCyr-Italic.svg#HelveticaNeueCyr-Italic') format('svg');
    }


    @font-face{
        font-family:"Helvetica Neue LT 75 cz";
        src: url('Hfonts/HelveticaNeueCE-Bold.eot');
	    src: url('Hfonts/HelveticaNeueCE-Bold.eot?#iefix') format('embedded-opentype'),
		url('Hfonts/HelveticaNeueCE-Bold.woff2') format('woff2'),
		url('Hfonts/HelveticaNeueCE-Bold.woff') format('woff'),
		url('Hfonts/HelveticaNeueCE-Bold.ttf') format('truetype'),
		url('Hfonts/HelveticaNeueCE-Bold.svg#HelveticaNeueCE-Bold') format('svg');
    }





html{
  font-size: 24Px;
}
body{
  font-size: 16px;
  line-height: 1em;
  font-family: 'Helvetica Neue LT W01_55 Roman',Helvetica , Arial, sans-serif;
  color: $black;
}

input{
    font-family: 'Helvetica Neue LT W01_55 Roman',Helvetica , Arial, sans-serif;
}

a{
  text-decoration: none;
  color: $medium_blue;
  &:hover{
    text-decoration: underline;
    color: $medium_blue;
  }
}


.bold{
    .lang-en & {
        font-family: 'Helvetica Neue LT W01_71488914 cyr',Helvetica , Arial, sans-serif;
    }
}

/*HelveticaNeueETW01-55Rg*/

%bold {

}

%italic{
  font-family:'Helvetica Neue LT W01_56 It',Helvetica , Arial, sans-serif;
}

.review .content h3, .page-features .page-headline h1, .testimonials-row .testimonial .text p.testimonial-content, .success_box i{
    font-family:'Helvetica Neue LT W01_56 It',Helvetica , Arial, sans-serif;
    font-style: unset;
}

.lang-ru{
     .review .content h3, .page-features .page-headline h1, .testimonials-row .testimonial .text p.testimonial-content,  .success_box i{
        font-family:'Helvetica Neue LT W01_56 It cyr',Helvetica , Arial, sans-serif;
        font-style: unset;
    }
}

#helpPage{
    h1{

      line-height: 1em;
      font-size: 48px;
      margin-top: -6px;
    }
}

  strong, b, .viewSale em,  #helpPage h1, .spForm .form-group label, .btn, .faceLift h1 b, .faceLift h2 b, .faceLift h3 b, .faceLift h4 b, .faceLift h5 b, .fltooltip, .content_cover h3, .blue_gradient_line .headLine, .help_crossroad .side_bar h4, .faq_page .related_faq h4, #helpful_no p, .faq_detail h2, .faq_detail ol.faq-numbered-h2 > li:before, .faq_detail ol.faq-numbered-h3 > li:before, .facelift_2017 .btn, .form_facelift label {
  font-family:'Helvetica Neue LT W01_71488914',Helvetica , Arial, sans-serif;
}

.lang-ru{
 strong, b, #helpPage h1, .spForm .form-group label, .btn, .faceLift h1 b, .faceLift h2 b, .faceLift h3 b, .faceLift h4 b, .faceLift h5 b, .fltooltip, .content_cover h3, .blue_gradient_line .headLine, .help_crossroad .side_bar h4, .faq_page .related_faq h4, #helpful_no p, .faq_detail h2, .faq_detail ol.faq-numbered-h2 > li:before, .faq_detail ol.faq-numbered-h3 > li:before, .facelift_2017 .btn, .form_facelift label {
      font-family: 'Helvetica Neue LT W01_71488914 cyr',Helvetica , Arial, sans-serif;
    }
}

.lang-cs{
 strong, b, #helpPage h1, .spForm .form-group label, .btn, .faceLift h1 b, .faceLift h2 b, .faceLift h3 b, .faceLift h4 b, .faceLift h5 b, .fltooltip, .content_cover h3, .blue_gradient_line .headLine, .help_crossroad .side_bar h4, .faq_page .related_faq h4, #helpful_no p, .faq_detail h2, .faq_detail ol.faq-numbered-h2 > li:before, .faq_detail ol.faq-numbered-h3 > li:before, .facelift_2017 .btn, .form_facelift label, .langueage_footer .drop-down a.ico-arrow-top {
      font-family: 'Helvetica Neue LT 75 cz',Helvetica , Arial, sans-serif;
    }
}


/*HelveticaNeueETW01-75Bd*/


#main{
    strong{
    }
    a{
        text-decoration: underline;
    }
    a.btn{
        text-decoration: none;
    }
}

.content_cover{
    h1{
        font-size: 36px;
        line-height: 1em;
        @media(width > $mobil){
            line-height: 54px;
            font-size: 48px !important;
        }
        @mixin padding bottom, 3;
    }
    h3{
        font-size: 24px;
        line-height: 36px;
        @mixin padding top, 6;
        @media(width > $mobil){
            @mixin padding top, 10;
        }
    }

    p{
        font-size: 16px;
        line-height: 24px;
       @mixin padding top, 4;
        @media(width > $mobil){
           @mixin padding top, 5;
        }
        &.faq-perex{
            padding-top: 0;
            color: $dark_gray;
            font-size: 24px;
            line-height: 38px;
        }
        &.text_desc{
            padding-top: 0;
            color: $dark_gray;
            font-size: 24px;
            line-height: 38px;
        }
    }
    img{
        display: block;
        max-width: 100%;
        height: auto;
        margin-top: 16px;
        box-shadow: 0px 0px 20px 0px #e6e6e6;
        max-height: 350px;
        @media(width > $mobil){
            margin-top: 36px;
        }
    }
}

.green_check{
    display: block;
    position: relative;
    padding-left: 24px;
    &:before{
        position: absolute;
        background-image: url('icons/CheckListGreen.svg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 17px;
        height: 17px;
        content:"";
        left: 0;
        top: 4px;
    }
}

@import "assets/grid";
@import "assets/colors";
@import "assets/typography";
@import "assets/mixins";

@import "components/header";
@import "components/buttons";
@import "components/form";
@import "components/footer";
@import "components/icons";
@import "components/content";
@import "components/mobile-nav";
@import "components/fancybox";


@import "pages/help";
@import "pages/faq";

.just-text ol{
  color: red;
}

.facelift_2017{
    .btn{
        font-size: 16Px;
        line-height: 38px;
        height: 40px;
        border-radius: 20px;
        @extend %bold;
        &:hover{
          text-decoration: none;
        }
        &.orange_b{
            color: white;
            background: #ff8200;
            background: linear-gradient(0deg, #e55a00 0%, #ff8200 100%);
            border-bottom: solid 1px #9b3c00;
            &:hover{
                background: linear-gradient(0deg, #e55a00 0%, #e55a00 100%);
            }
        }
        &.green_b{
          color: white;
          background: #92bc1f;
          background: linear-gradient(0deg, #799c19 0%, #92bc1f 100%);
          border-bottom: solid 1px #496300;
          &:hover{
            background: #799c19;
            background: linear-gradient(0deg, #799c19 0%, #799c19 100%);
          }
        }
        &.gray_a{
          color: #2f3642;
          background: #f7f7f7 ;
          background: linear-gradient(0deg, #e6e6e6  0%, #ffffff  100%);;
          border-bottom: solid 1px #999999 ;
          &:hover{
            background: #ffffff ;
            background: linear-gradient(0deg, #ffffff  0%, #ffffff  100%);
          }
        }
        &.gray_b{
          color: #2f3642;
          background: #dadada ;
          background: linear-gradient(0deg, #cecece 0%, #e6e6e6 100%);;
          border-bottom: solid 1px #999999 ;
          &:hover{
            background: #e6e6e6;
            background: linear-gradient(0deg, #e6e6e6 0%, #e6e6e6 100%);
          }
        }
        &.lg{
          @media(width > 630px){
            border-radius: 36px;
            font-size: 24Px;
            height: 72px;
            line-height: 70px;
            padding-left: 40px;
            padding-right: 40px;
          }

        }
    }
    .btn span.ico-dwnl{
      position: relative;
      padding-left: 24px;
      display: inline-block;
    }
    .btn span.ico-dwnl:before{
      position: absolute;
      background-image: url('icons/Download.svg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 15px;
      height: 19px;
      content:"";
      left: 0;
      top: 10px;
    }

    button.clear{
      background-color: transparent;
      border: none;
      -webkit-appearance: none;
      outline: 0;
      text-decoration: none;
    }

}


.form_facelift{
    margin-bottom: 40px;
    font-family: 'HelveticaNeueETW01-55Rg',Helvetica , Arial, sans-serif;
    input[type=text]{
        box-shadow: none;
        height: 46px;
        @media(width > 630px){
        line-height: 48px;
          height: 48px;
        }

        padding-left: 14px;
        padding-right: 14px;
        border: solid 1px $medium_gray;
        font-size: 16Px;
        @media(width > $mobil){
          font-size: 21Px;
        }
    }
    textarea{
      box-shadow: none;
      padding: 14px;
      height: 240px;
      box-shadow: inset 0 1px 1px rgba(0,0,0,.0);
      border: solid 1px $medium_gray;
      font-size: 16Px;
      line-height: 1em;
      color: $superdark_grey;
      @media(width > $mobil){
        font-size: 21px;
      }
    }
    input[type=text], textarea{
      font-weight: normal;


    }
    input[type=text]:focus, textarea:focus{
      outline-color: $superlight_yellow;
    }


    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $dark_gray;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: $dark_gray;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: $dark_gray;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: $dark_gray;
    }


    .label.col12{
        width: 100%;
    }

    label{
      padding-left: 14px;
      position: relative;
      display: block;
      font-size: 16Px;
      @extend %bold;
      @mixin margin top, 4;
      @mixin padding bottom, 2;
      @media(width > $tablet){
        font-size: 21Px;
        @mixin margin top, 6;
        @mixin padding bottom, 3;
      }

      text-align: left;
    }

    .form_place{
        background-color: $superlight_gray;
        padding: 18px;
    }

    .button_label{
        padding-top: 19px;
        text-align: center;
    }

}

#formSending{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $superlight_gray;
  padding: 18px;
  &.hide{
    display: none;
  }
  .spin{
    width: 90px;
    animation: spin 2s linear infinite;
  }
}

.ok_place{
  @mixin padding top, 9;
  @mixin padding bottom, 9;
  background-color: $superlight_gray;

  .success{
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 75px;
    text-align: center;
    background-image: url('icons/OkGreen.svg');
    background-repeat: no-repeat;
    background-size: 50px 50px;
    content:"";
    background-position: center top;
    h4{
      font-size: 34Px;
      line-height: 40Px;
      font-weight: 500;
      font-family: 'HelveticaNeueETW01-55Rg',Helvetica , Arial, sans-serif;
    }
    p{
      @mixin padding top, 6;
      font-size: 16Px;
      line-height: 24Px;
    }
    #emailPlaceHolder{
      display: block;
      font-weight: strong;
      font-weight: 600;
    }

  }
}

.about-us-partners .marek_line{
  padding-bottom: 50px;
}



@-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}


.btn{
    font-size: 16px;
    height: 40px;
    border-radius: 20px;
    &:hover{
      text-decoration: none;
    }
    &.orange_b, &.color-1{
        color: white;
        background: #ff8200;
        background: linear-gradient(0deg, #e55a00 0%, #ff8200 100%);
        border-bottom: solid 1px #9b3c00;
        &:hover{
            background: linear-gradient(0deg, #e55a00 0%, #e55a00 100%);
        }
    }
    &.green_b, &.color-1{
      color: white;
      background: #92bc1f;
      background: linear-gradient(0deg, #799c19 0%, #92bc1f 100%);
      border-bottom: solid 1px #496300;
      &:hover{
        background: #799c19;
        background: linear-gradient(0deg, #799c19 0%, #799c19 100%);
      }
    }
    &.gray_a{
      color: #2f3642;
      background: #f7f7f7 ;
      background: linear-gradient(0deg, #e6e6e6  0%, #ffffff  100%);;
      border-bottom: solid 1px #999999 ;
      &:hover{
        background: #ffffff ;
        background: linear-gradient(0deg, #ffffff  0%, #ffffff  100%);
      }
    }
    &.gray_b{
      color: #2f3642;
      background: #dadada ;
      background: linear-gradient(0deg, #cecece 0%, #e6e6e6 100%);;
      border-bottom: solid 1px #999999 ;
      &:hover{
        background: #e6e6e6;
        background: linear-gradient(0deg, #e6e6e6 0%, #e6e6e6 100%);
      }
    }
    &.large{
      @media(width > 630px){
        border-radius: 36px;
        font-size: 24px;
        height: 72px;
        line-height: 70px;
        padding-left: 40px;
        padding-right: 40px;
      }
    }
    &.xl{
        height: 72px;
    }
}
.btn span.ico-dwnl{
  position: relative;
  padding-left: 24px;
  display: inline-block;
}
.btn span.ico-dwnl:before{
  position: absolute;
  background-image: url('icons/Download.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 15px;
  height: 19px;
  content:"";
  left: 0;
  top: 10px;
}

button.clear{
  background-color: transparent;
  border: none;
  -webkit-appearance: none;
  outline: 0;
  text-decoration: none;
}

.btn{
    line-height: 38px;
    &.xl{
        line-height: 72px;
    }
}
.lang-cs, .lang-ru{
    .btn{
        line-height: 43px;
        &.lg{
            line-height: 60px;
        }
        &.large{
            @media(width > 630px){
                line-height: 72px;
            }
        }
        &.xl{
            line-height: 76px;
        }
    }
}

#header_facelift {
    position: relative;
}

.mobile_nav {
    display: none;
    
    @media(width < $mobil){
        &.show{
            display: block;
        }
    }
    lost-utility: clearfix;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 99;
    .close {
        height: 60px;
        background-color: $medium_blue;
        width: 114px;
        float: right;
        border-bottom: solid 1px $superlight_blue;
        background-image: url('icons/MultiplyWhite.svg');
        background-repeat: no-repeat;
        background-size: 14px 14px;
        background-position: center;
    }
    .level-list {
        float: left;
        width: 100%;
        background: white;
    }
    ul {
        &.collapse {
            display: none;
            &.in {
                display: block;
            }
        }
        
        li{
            position: relative;
            &.level-0{
                &:last-child{
                    border-bottom: solid 2px  $medium_gray;
                }
            }
            

            a{
                display: block;
                background-color: $medium_blue;
                color: white;
                &.level-0{
                    line-height: 60px;
                    font-size: 21px;
                    padding-left: 22px;
                    border-bottom: solid 1px $superlight_blue;
                }
                &.cc{
                    position: absolute;
                    right: 0;
                    width: 60px;
                    height: 48px;
                    z-index: 1;
                    top: 6px;
                    border-left: dashed 1px $superlight_blue;
                    background-image: url('icons/CollapseArrowWhite.svg');
                    background-repeat: no-repeat;
                    background-size: 22px 12px;
                    background-position: center;
                    &.collapsed{
                        background-image: url('icons/ExpandArrowWhite.svg');
                    }
                }

                &.level-1{
                    line-height: 48px;
                    padding-left: 40px;
                    &.active{
                        background: $superdark_grey;
                    }
                }

                &.level-0.sticky_account{
                  background-image: url('icons/Lock.svg');
                  background-repeat: no-repeat;
                  background-size: 17px 22px;
                  background-position: 22px center;
                  padding-left: 50px;
                }
            }
        }
        .white-menu{
           
            a{
                background: white;
                color: $medium_blue;
                &.level-0{
                    border-bottom: solid 1px  $light_gray;
                }
                
                &.cc{
                    background-image: url('icons/CollapseArrowBlue.svg');
                    &.collapsed{
                        background-image: url('icons/ExpandArrowBlue.svg');

                    }
                }
                &.sticky_account{
                  border-bottom: solid 1px  $medium_gray !important;
                }
            }
        }
    }
    .collapsing {
        position: relative;
        height: 0;
        overflow: hidden;
        transition: height .35s ease;
    }
}